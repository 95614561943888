<template>
  <div id="con">
    <el-container style="height: 100vh; border: 1px solid #eee" v-cloak>
      <div class="left-all">
        <div class="bigTitle">
          <el-button
            type="primary"
            size="mini"
            style="float: left"
            @click="goTo"
            >首页</el-button
          >{{ listArry[0].classname }}
        </div>
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          style="height: calc(100vh - 61px); overflow: auto"
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-platform"></i>
              <span>{{
                listArry.length > 0 ? listArry[0].classname : "暂无章节"
              }}</span>
            </template>
            <el-menu-item-group v-for="(item, index) in listArry" :key="index">
              <!-- <template slot="title">{{t.title}}</template> -->
              <el-menu-item
                :index="item.sort"
                style="
                  margin-bottom: 40px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ item.title
                }}<i
                  style="position: absolute; left: 10px; top: 16px"
                  class="el-icon-check"
                  v-if="item.already == 1"
                ></i
                ><i
                  style="position: absolute; right: 10px; top: 16px"
                  class="el-icon-video-play"
                ></i
                ><i
                  class="gol"
                  @click="
                    doLx(
                      item.qid,
                      item.title,
                      item.userid,
                      item.jobid,
                      item._id
                    )
                  "
                  >开始练习 <i class="el-icon-edit"></i
                ></i>
              </el-menu-item>
              <!-- <template slot="title">{{item.title}}</template>
                            <el-menu-item :index="item.sort"> <i style=" float: left;margin-top: 14px;margin-left:-20px;"
                                class="el-icon-check" v-if="item.already==1"></i>{{item.title}}--学习</el-menu-item>
                            <el-menu-item  @click="doLx(item.qid,item.title)" >去练习 →</el-menu-item> -->

              <!-- <el-menu-item :index="t.lxIndex">
                                
                            </el-menu-item> -->
            </el-menu-item-group>

            <!-- <el-menu-item-group>
                        <template slot="title">中阶scratch</template>
                        <el-menu-item index="1-1-2">学习</el-menu-item>
                        <el-menu-item index="1-2-2">练习</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group>
                        <template slot="title">提高scratch</template>
                        <el-menu-item index="1-1-3">学习</el-menu-item>
                        <el-menu-item index="1-2-3">练习</el-menu-item>
                    </el-menu-item-group> -->
          </el-submenu>
          <!-- <el-menu-item index="2">
                    <i class="el-icon-menu"></i>
                    <span slot="title">导航二</span>
                </el-menu-item>
                <el-menu-item index="3" disabled>
                    <i class="el-icon-document"></i>
                    <span slot="title">导航三</span>
                </el-menu-item>
                <el-menu-item index="4">
                    <i class="el-icon-setting"></i>
                    <span slot="title">导航四</span>
                </el-menu-item> -->
        </el-menu>
      </div>
      <!-- </el-aside> -->
      <el-container style="border: 1px solid #eee">
        <el-header class="title-con">
          <div class="title-color">
            <span>{{ changeTitle }}</span>
            <span>本章已学习时长：{{ counttime }}</span>
            <!-- <ul class="title-ul">
                            <li v-for="item in 10" class="title-li" ></li>
                        </ul>
                        <p>1/10课程</p> -->
          </div>
        </el-header>
        <el-main>
          <div  v-for="(item, index) in listArry"   :key="index">
            <div
             
              v-if="currentIndex == index"
            
            >
              <div class="video-bg">
                <iframe
                  width="100%"
                  height="605"
                  style="margin-top: -20px"
                  :src="item.url"
                  scrolling="no"
                  border="0"
                  frameborder="no"
                  framespacing="0"
                  allowfullscreen="true"
                >
                </iframe>
                <!-- <iframe style="width:100%;height:100%" src="//player.bilibili.com/player.html?aid=724511968&bvid=BV1fS4y167A7&cid=541692832&page=1&high_quality=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe> -->
              </div>
              <div style="display: flex; margin-top: 10px">
                <p style="flex: 15; line-height: 22px">{{ changeDetail }}</p>
                <el-button
                  style="
                    flex: 1;
                    height: 40px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  type="primary"
                  @click="
                    doLx(
                      item.qid,
                      item.title,
                      item.userid,
                      item.jobid,
                      item._id
                    )
                  "
                >
                  开始练习 <i class="el-icon-edit"></i>
                </el-button>
              </div>
              <p
                v-html="item.content"
                style="padding: 20px 0; white-space: pre-wrap"
              ></p>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCollapse: false,
      currentIndex: 0,
      cilickIndex: null,
      changeTitle: "",
      counttime: null,
      changeId: "",
      changeDetail: "",
      value2: 0,
      timer: null,
      timerF: null,
      listArry: [{ classname: "scratch编程", sort: 1 }],
      timeE: null,
      timeEnd: 0,
      timeId: null,
      type:null
    };
  },
  format(percentage) { 
    return percentage === 100 ? "满" : `${percentage}%`;
  },
  components: {
    // headerBg: httpVueLoader('./userinfo'),
  },
  created() {
    window.addEventListener("beforeunload", this.leaveConfirm);
  },
  mounted() {
    this.getList();
    // this.type = this.$route.query.type
  },

  methods: {
    goTo() {
        if(window.localStorage.getItem('anjing-token-group') =='parents'){
            this.$router.push({ path: "./pre" });
        }
        else {
            this.$router.push({ path: "./list" });
        }
      
    },
    leaveConfirm(e) {
      this.timeLong(this.timeEnd, this.changeId);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      // 点击章节切换 清理定时器  并归零
      clearInterval(this.timeE);

      this.timeLong(this.timeEnd, window.localStorage.getItem("timeId"));
      this.getList(false);
      console.log(window.localStorage.getItem("timeId"));

      this.timeEnd = 0;
      this.timeE = setInterval(() => {
        this.timeEnd++;
        console.log(this.timeEnd);
      }, 1000);

      clearTimeout(this.timer);
      clearTimeout(this.timerE);

      console.log(key, keyPath);

    //   let Request = this.GetRequest();
      let type = Number( this.$route.query.pathNum);
      this.currentIndex = key - 1 - type;

      this.changeTitle = this.listArry[keyPath[1] - 1 - type].title;
      this.counttime = this.listArry[keyPath[1] - 1 - type].counttime;
      this.changeDetail = this.listArry[keyPath[1] - 1 - type].intro;
      this.changeId = this.listArry[keyPath[1] - 1 - type]._id;
      window.localStorage.setItem("timeId", this.changeId);
      console.log(window.localStorage.getItem("timeId"));
      this.duration = this.listArry[keyPath[1] - 1 - type].duration;
      this.duration = this.listArry[keyPath[1] - 1 - type].duration;

      // if (this.listArry[keyPath[1] - 1].already == 0) {
      //     this.timeList()
      // }
    },
    doLx(qid, title, userid, jobid, id, e) {
      try {
        e.stopPropagation(); //非IE浏览器
      } catch (e) {
        window.event.cancelBubble = true; //IE浏览器
      }
      // 点击练习 清理定时器  并归零
      clearInterval(this.timeE);
      this.timeLong(this.timeEnd, id);
      this.timeEnd = 0;
      this.getList(false);
      console.log("898989");

    //   window.open(
    //     `./scratchVideo.html?title=${title}&qid=${qid}&userid=${userid}&jobid=${jobid}&id=${id}`
    //   );
      //list-scratch-video
    //   this.$router.push({
    //     path:'list-scratch-video',query:{title:title,qid:qid,userid:userid,jobid:jobid,id:id`}
    // })
    let routeUrl = this.$router.resolve({path:'/list-scratch-video',query:{
        title:title,qid:qid,userid:userid,jobid:jobid,id:id

        }});
        window.open(routeUrl.href, '_blank')
    },
    

    // 获取所有课程
    getList(time = true) {
      // 一开始加载时长归零
      if (time) {
        this.timeEnd = 0;
      }

    //   var params = new URLSearchParams();
    
    //   params.append("op", "getlist");
    // debugger;
    //   let Request = this.GetRequest();
    //   let type = Request["type"];
    //   params.append("type", type); //Scratch编程基础 /Scratch编程计算思维
      // params.append('_id', _id)
      console.log(this.$route.query.type)
      let data = {
        op: "getlist",
        type: this.$route.query.type,
      };
      let _this = this;
      this.$ajax.post(
        this.url + "/student/courses.do",
        _this.Qs.stringify(data)
      ).then(function (response) {
          if (response.data.status == 0) {
            _this.listArry = response.data.data;
            if (time) {
              _this.changeTitle = _this.listArry[0].title;

              _this.changeDetail = _this.listArry[0].intro;
              // _this.startV(_this.listArry[0]._id);
              _this.changeId = _this.listArry[0]._id;

              window.localStorage.setItem("timeId", _this.changeId);
              _this.counttime = _this.listArry[0].counttime;

              _this.duration = _this.listArry[0].duration;
             
              _this.timeE = setInterval(() => {
                _this.timeEnd++;

                console.log(_this.timeEnd);
              }, 1000);
            }
            // 每次增加1秒
          } else {
            // console.log(response.data.msg);
            _this.$message.info(response.data.msg)
            // alert(response.data.msg);
            // window.location.href = "/loginChange.html";
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // GetRequest() {
    //   var url = location.search; //获取url中"?"符后的字串
    //   var theRequest = new Object();
    //   if (url.indexOf("?") != -1) {
    //     var str = url.substr(1);
    //     strs = str.split("&");
    //     for (var i = 0; i < strs.length; i++) {
    //       theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
    //     }
    //   }
    //   return theRequest;
    // },
    //时长
    timeLong(time, id) {
      var params = new URLSearchParams();
    //   var _this = this;
    //   params.append("op", "recordlog");
    //   params.append("type", "学习");
    //   params.append("time", time);
    //   params.append("_id", id);
    //   axios
    //     .post("/classes.do", params)
    let data = {
        op: "recordlog",
        type:'学习',
        time:time,
        _id:id
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/student/courses.do", _this.Qs.stringify(data))

        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    },
}
  
};
</script>
<style scoped>
body,
div,
ul,
li,
p {
  padding: 0;
  margin: 0;
  list-style: none;
}

[v-cloak] {
  display: none;
}

.left-all {
  background: #ececec;
}
#con{
    text-align: left;;
}

.title-con {
  width: 100%;
  padding: 10px;
  background-color: #1890ff !important;
  color: #fff;
}

.gol {
  position: absolute;
  right: 0px;
  padding-right: 12px;
  top: 44px;
  width: 100%;
  text-align: right;
  border-top: 1px solid #f7f7f7;
  /* background: #f7f7f7; */
  /* height: 20px; */
  /* border-radius: 5px; */
  /* color: #409EFF !important; */
  font-weight: bold;
  /* background-color: #fff; */
}

.title-all {
  text-align: center;
  padding: 10px 0;
  color: #1890ff;
}

.el-header {
  background-color: #efefef;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.el-submenu {
  width: 280px;
}

.el-menu-item-group__title {
  background: #f0f0f0;
}

.el-menu-item.is-active {
  background: #ecf5ff;
}

.bigTitle {
  text-align: center;
  border-bottom: 1px solid #eee;
  padding: 19px 10px;
}

.el-menu {
  border-right: none;
}

.title-color {
  line-height: 42px;
  color: #fff;
  display: flex;
 
}

.title-color span {
  flex: 1;
}

.title-color p {
  flex: 1;
}

/* .lx-bottom{
        position: fixed;
    bottom: 40px;
    left: 38px;
    } */
/* .el-button{
        padding:6px 20px;
    } */
.video-bg {
  background-color: #000;
  /* height: 700px; */
  margin-top: 10px;
  box-shadow: 0 0 5px 0px;
  color: #fff;
  /* line-height: 500px; */
  text-align: center;
}

.title-ul {
  flex: 3;
}

.title-li {
  width: 7%;
  height: 5px;
  border-radius: 2px;
  background: #323263;
  float: left;
  margin-right: 3px;
  margin-top: 18px;
}
</style>