var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"con"}},[_c('el-container',{staticStyle:{"height":"100vh","border":"1px solid #eee"}},[_c('div',{staticClass:"left-all"},[_c('div',{staticClass:"bigTitle"},[_c('el-button',{staticStyle:{"float":"left"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.goTo}},[_vm._v("首页")]),_vm._v(_vm._s(_vm.listArry[0].classname)+" ")],1),_c('el-menu',{staticClass:"el-menu-vertical-demo",staticStyle:{"height":"calc(100vh - 61px)","overflow":"auto"},attrs:{"default-active":"1"},on:{"open":_vm.handleOpen,"close":_vm.handleClose,"select":_vm.handleSelect}},[_c('el-submenu',{attrs:{"index":"1"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-s-platform"}),_c('span',[_vm._v(_vm._s(_vm.listArry.length > 0 ? _vm.listArry[0].classname : "暂无章节"))])]),_vm._l((_vm.listArry),function(item,index){return _c('el-menu-item-group',{key:index},[_c('el-menu-item',{staticStyle:{"margin-bottom":"40px","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"index":item.sort}},[_vm._v(" "+_vm._s(item.title)),(item.already == 1)?_c('i',{staticClass:"el-icon-check",staticStyle:{"position":"absolute","left":"10px","top":"16px"}}):_vm._e(),_c('i',{staticClass:"el-icon-video-play",staticStyle:{"position":"absolute","right":"10px","top":"16px"}}),_c('i',{staticClass:"gol",on:{"click":function($event){return _vm.doLx(
                    item.qid,
                    item.title,
                    item.userid,
                    item.jobid,
                    item._id
                  )}}},[_vm._v("开始练习 "),_c('i',{staticClass:"el-icon-edit"})])])],1)})],2)],1)],1),_c('el-container',{staticStyle:{"border":"1px solid #eee"}},[_c('el-header',{staticClass:"title-con"},[_c('div',{staticClass:"title-color"},[_c('span',[_vm._v(_vm._s(_vm.changeTitle))]),_c('span',[_vm._v("本章已学习时长："+_vm._s(_vm.counttime))])])]),_c('el-main',_vm._l((_vm.listArry),function(item,index){return _c('div',{key:index},[(_vm.currentIndex == index)?_c('div',[_c('div',{staticClass:"video-bg"},[_c('iframe',{staticStyle:{"margin-top":"-20px"},attrs:{"width":"100%","height":"605","src":item.url,"scrolling":"no","border":"0","frameborder":"no","framespacing":"0","allowfullscreen":"true"}})]),_c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_c('p',{staticStyle:{"flex":"15","line-height":"22px"}},[_vm._v(_vm._s(_vm.changeDetail))]),_c('el-button',{staticStyle:{"flex":"1","height":"40px","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.doLx(
                    item.qid,
                    item.title,
                    item.userid,
                    item.jobid,
                    item._id
                  )}}},[_vm._v(" 开始练习 "),_c('i',{staticClass:"el-icon-edit"})])],1),_c('p',{staticStyle:{"padding":"20px 0","white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(item.content)}})]):_vm._e()])}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }